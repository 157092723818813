import React from 'react';
import PropTypes from 'prop-types';
import { filter, fromPairs, includes, keys } from 'ramda';
import { useSelector } from 'react-redux';
import { Button, Text } from '@ci/atoms';
import { Box, Grid } from '@myci/ui-components';
import { FileInputField, Form, SelectField, TextInputField } from '@myci/ui-components-redux';
import { Message } from '@myci/intl';
import { hasLengthMax, isRequired } from '@validarium/validations';
import { validate } from '@validarium/core';
import {
	companyAttachmentFieldsConfig,
	getDoesCompanyFieldExist,
	useAddressGenerationHandler,
	useCityNames,
	useCompanySpecificIdentifierDetail,
} from '@myci/domain-instance-omn';
import { getIsUserTypeNumberCompany } from '@myci/domain-subscriptions';
import { isPendingRegistration } from '@myci/domain-registration';
import { CountrySelectField, messages as registrationMessages } from '@myci/module-registration';
import m from '../../../../messages';

export const validatePersonalInfo = validate({
	city: [isRequired],
	address: [isRequired, hasLengthMax(50)],
	postalCode: [isRequired],
});

export const validatePersonalInfoIndividual = validate({
	idCardScan: [isRequired],
	idCardScan_sideB: [isRequired],
});

export const validatePersonalInfoCompany = values => {
	const validationPairs = companyAttachmentFieldsConfig.map(field => [field.name, [isRequired]]);

	return validate(fromPairs(validationPairs))(values);
};

const PersonalInfoForm = ({
	allowedCountries,
	form,
	handleSubmit,
	onSubmit,
	pristine,
	invalid,
	userType,
}) => {
	const handleAddressSegmentChange = useAddressGenerationHandler(form);
	const companySpecificIdentifierDetail = useCompanySpecificIdentifierDetail(form);
	const isCompany = getIsUserTypeNumberCompany(userType);
	const isSubmitting = useSelector(isPendingRegistration);
	const cityNames = useCityNames(userType);

	return (
		<Form
			onSubmit={handleSubmit(values => {
				if (isCompany) {
					return onSubmit({
						...values,
						form,
					});
				}
				return onSubmit({
					...values,
					name: values.fullName,
					form,
				});
			})}
		>
			<Box as="section" className="section--bordered" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							<Grid alignItems="center" mb={3}>
								<Text variant="tagline" icon="individual">
									<Message {...registrationMessages.personalInfo} />
								</Text>
							</Grid>
							<Grid row>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<TextInputField
										label={<Message {...registrationMessages.fieldStreet} />}
										name="street"
										required
										onChange={handleAddressSegmentChange}
									/>
								</Grid>
								<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
									<SelectField
										label={<Message {...registrationMessages.fieldCity} />}
										name="city"
										items={cityNames}
										required
									/>
								</Grid>
								<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
									<TextInputField
										label={<Message {...registrationMessages.fieldPostalCode} />}
										name="postalCode"
										required
										onChange={handleAddressSegmentChange}
									/>
								</Grid>
								<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
									<CountrySelectField
										formValueProp="key"
										filterItems={filter(country =>
											includes(country.key.toString(), keys(allowedCountries))
										)}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Box as="section" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							<Grid alignItems="center" mb={3}>
								<Text variant="tagline" icon="info">
									<Message {...registrationMessages.additionalInfo} />
								</Text>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Box as="section" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							<Grid alignItems="center" mb={3}>
								<Text variant="tagline">
									<Message {...registrationMessages.scanId} />
								</Text>
							</Grid>
							<Grid row>
								{isCompany ? (
									<>
										{companyAttachmentFieldsConfig.map(({ name, acceptedExtensions }) =>
											getDoesCompanyFieldExist(name, companySpecificIdentifierDetail) ? (
												<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
													<FileInputField
														key={name}
														name={name}
														label={<Message {...m[`field_${name}`]} />}
														defaultFileName={name}
														acceptedExtensions={acceptedExtensions}
														required
													/>
												</Grid>
											) : null
										)}
									</>
								) : (
									<>
										<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
											<FileInputField
												name="idCardScan"
												label={<Message {...registrationMessages.fieldAttachment} />}
												defaultFileName="ID_Front"
												required
											/>
										</Grid>
										<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
											<FileInputField
												name="idCardScan_sideB"
												label={<Message {...registrationMessages.fieldAttachmentSideB} />}
												defaultFileName="ID_Back"
												required
											/>
										</Grid>
									</>
								)}
							</Grid>
							<Grid mb={{ xs: 2, lg: 0 }} mt={5}>
								<Button type="submit" isDisabled={pristine || invalid} isBusy={isSubmitting}>
									<Message {...registrationMessages.registerButton} />
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<TextInputField type="hidden" name="address" />
		</Form>
	);
};

PersonalInfoForm.propTypes = {
	allowedCountries: PropTypes.object,
	form: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func,
	pristine: PropTypes.bool.isRequired,
	userType: PropTypes.number.isRequired,
};

export default PersonalInfoForm;
