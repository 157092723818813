import { useMemo } from 'react';
import { combineValidate } from '@validarium/core';
import { VerifyEmailForm, VerifyPhoneForm, validateVerifyEmail } from '@myci/module-registration';
import Payment from '@myci/module-registration/Payment';
import { PAYMENT_GATEWAY_METHOD } from '../../constants';

import PersonalInfoForm, {
	validatePersonalInfo,
	validatePersonalInfoCompany,
	validatePersonalInfoIndividual,
} from './components/journey/PersonalInfoForm';
import GeneralInfoForm, {
	validateGeneralInfoCompany,
	validateGeneralInfoIndividual,
} from './components/journey/GeneralInfoForm';
import Success from './components/journey/Success';

export const useMyCiRegistrationConfig = () =>
	useMemo(
		() => ({
			registrationJourney: {
				generalInfoForm: GeneralInfoForm,
				payment: Payment,
				personalInfoForm: PersonalInfoForm,
				verifyEmailForm: VerifyEmailForm,
				verifyPhoneForm: VerifyPhoneForm,
				success: Success,
			},
			registrationJourneyValidateIndividual: combineValidate(
				validateGeneralInfoIndividual,
				validatePersonalInfo,
				validatePersonalInfoIndividual,
				validateVerifyEmail
			),
			registrationJourneyValidateCompany: combineValidate(
				validateGeneralInfoCompany,
				validatePersonalInfo,
				validatePersonalInfoCompany,
				validateVerifyEmail
			),
			registrationJourneyAsyncBlurFields: ['email', 'password', 'phoneNumber', 'NationalId'],
			registrationJourneyAllowedCountries: {
				164: 'Oman',
			},
			paymentGatewayMethod: PAYMENT_GATEWAY_METHOD,
		}),
		[]
	);
