import { Message } from '@myci/intl';
import { Box, Button, Grid, Icon, Text } from '@myci/ui-components';
import { Form, FormError } from '@myci/ui-components-redux';
import PropTypes from 'prop-types';
import React from 'react';

import m from '../../messages';

const Payment = ({ handleSubmit }) => (
	<Form onSubmit={handleSubmit}>
		<Box as="section" py={4}>
			<FormError />
			<Grid container>
				<Grid row>
					<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
						<Grid alignItems="center" mb={3} flexDirection="column">
							<React.Fragment>
								<Icon type="finance" color="teal" size="50" my={2} />
								<Text mb={4} size="lg" weight="bold">
									<Message {...m.proceedToPayment} />
								</Text>
								<Button kind="primary" type="submit" mt={2}>
									<Message {...m.pay} />
								</Button>
							</React.Fragment>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	</Form>
);

Payment.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

export default Payment;
