import React, { lazy } from 'react';
import { Message } from '@myci/intl';
import { withReducers } from 'redux-syringe';
import { useSelector } from 'react-redux';
import { SubscriptionCardsSkeletor, USERS_DICT } from '@myci/domain-subscriptions';
import { forms, getUserType, reducer as registration } from '@myci/domain-registration';
import { Grid, Heading, Img, SafeSuspense, Section, Text } from '@myci/ui-components';
import { useJourneyCurrentStep } from '@myci/ui-components-redux';
import m from 'gatsby-theme-myci/src/messages';
import PublicLayout from 'gatsby-theme-myci/src/layouts/PublicLayout';

import { useMyCiRegistrationConfig } from '../../modules/myci-registration/config';

const RegistrationJourney = lazy(() => import('@myci/module-registration/RegistrationJourney'));

const Registration = () => {
	const myciRegistrationConfiguration = useMyCiRegistrationConfig();
	const journeyCurrentStep = useJourneyCurrentStep(forms.registration);
	const userType = useSelector(getUserType);

	const {
		registrationJourney: {
			generalInfoForm,
			payment,
			personalInfoForm,
			verifyPhoneForm,
			verifyEmailForm,
			success,
		},
		registrationJourneyValidateIndividual,
		registrationJourneyValidateCompany,
		registrationJourneyAllowedCountries,
		registrationJourneyAsyncBlurFields,
		paymentGatewayMethod,
	} = myciRegistrationConfiguration;

	return (
		<PublicLayout
			headerClassName="section--registration"
			header={
				(!journeyCurrentStep || journeyCurrentStep <= 1) && (
					<Section pb={{ xs: 4, lg: 0 }} pt={5}>
						<Grid container>
							<Grid row>
								<Grid
									col={{ xs: 12, lg: 5 }}
									pr={{ md: 0 }}
									offset={{ lg: 2 }}
									order={{ xs: 2, lg: 1 }}
								>
									<Heading align={{ xs: 'center', lg: 'left' }} className="section__heading">
										{userType && <Message {...m[`registrationTitle${USERS_DICT[userType]}`]} />}
									</Heading>
									<Text align={{ xs: 'center', lg: 'left' }} className="text-md" mt={3}>
										<Message {...m.registrationDescription} />
									</Text>
								</Grid>
								<Grid col={{ xs: 12, lg: 5 }} order={{ xs: 1, lg: 2 }} className="text-center">
									<Img
										className="reg-img img-fluid mb-0"
										src={require('@myci/assets/images/reg.png')}
										alt=""
									/>
								</Grid>
							</Grid>
						</Grid>
					</Section>
				)
			}
		>
			<SafeSuspense fallback={<SubscriptionCardsSkeletor />}>
				<RegistrationJourney
					validateIndividual={registrationJourneyValidateIndividual}
					validateCompany={registrationJourneyValidateCompany}
					renderPersonalInfoForm={personalInfoForm}
					renderPayment={payment}
					renderVerifyPhoneForm={verifyPhoneForm}
					renderVerifyEmailForm={verifyEmailForm}
					renderSuccess={success}
					renderGeneralInfoForm={generalInfoForm}
					asyncBlurFields={registrationJourneyAsyncBlurFields}
					allowedCountries={registrationJourneyAllowedCountries}
					paymentGatewayMethod={paymentGatewayMethod}
				/>
			</SafeSuspense>
		</PublicLayout>
	);
};

export default withReducers({ registration }, { isGlobal: true })(Registration);
