import { Script } from 'gatsby';
import {
	getSubscriptionPlan,
	setCaptchaResponse,
	setSubscriptionPlan,
} from '@myci/domain-registration';
import {
	IDENTIFIER_TYPE_FIELD,
	getIsFieldRequiredWithIdentifier,
	identifierValidationsConfig,
	useNationalitySpecificIdentifier,
	useSelectableCompanyIdentifier,
} from '@myci/domain-instance-omn';
import { PricingTable, USERS_DICT, getIsUserTypeNumberCompany } from '@myci/domain-subscriptions';
import { LookupSelectField, Lookups } from '@myci/domain-lookups';
import { useInstanceConfig } from '@myci/instances';
import { Message } from '@myci/intl';
import { PhoneInputFieldDeprecated, isPossiblePhoneNumber } from '@myci/phone-numbers';
import { getCurrentInstanceEnv } from '@myci/utils';
import { useAmountFormatter } from '@ci/formatters';
import { useTimeSpanDurationFormatter } from '@myci/formatters';
import {
	TermsAndConditionsTextContainer,
	UserTypeSwitch,
	messages as registrationMessages,
} from '@myci/module-registration';
import { PasswordInputFieldDeprecated, validatePasswordsConfirm } from '@myci/password';
import {
	AnchorLink,
	Box,
	Br,
	Button,
	Grid,
	Heading,
	MandatoryFieldsText,
	Section,
	Textarea,
} from '@myci/ui-components';
import { Icon, Text } from '@ci/atoms';
import { CheckboxField, DatePickerField, Form, TextInputField } from '@myci/ui-components-redux';
import { isAdult, isIso8601Date } from '@myci/validations';
import { validate } from '@validarium/core';
import { hasLengthMax, isEmail, isRequired } from '@validarium/validations';
import PropTypes from 'prop-types';
import { mergeDeepWithKey } from 'ramda';
import { alwaysNull } from 'ramda-extension';
import React, { Fragment, useEffect, useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { adultConstraint, pastConstraint } from '@ci/dates';
import { fetchTermsAndConditions, selectTermsAndConditions } from '@myci/domain-data-api';
import m from '../../../../messages';

const { recaptchaSiteKey, recaptchaTurnedOn } = getCurrentInstanceEnv();

const commonValidationFields = {
	agree: [isRequired],
	email: [isRequired, isEmail],
	phoneNumber: [isRequired, isPossiblePhoneNumber],
	password: [isRequired],
	confirmPassword: [isRequired],
};

export const validateGeneralInfoIndividual = values => {
	const identifierType = values[IDENTIFIER_TYPE_FIELD];
	const identifierSpecificValidations = identifierValidationsConfig[identifierType] ?? [];

	const validariumValidations = validate({
		...commonValidationFields,
		nationality: [isRequired],
		fullName: [isRequired, hasLengthMax(128)],
		dateOfBirth: [isRequired, isIso8601Date, isAdult()],
		[identifierType]: identifierSpecificValidations,
	})(values);

	return mergeDeepWithKey(alwaysNull, validariumValidations, validatePasswordsConfirm(values));
};

export const validateGeneralInfoCompany = values => {
	const identifierType = values[IDENTIFIER_TYPE_FIELD];
	const identifierSpecificValidations = identifierValidationsConfig[identifierType] ?? [];

	const validariumValidations = validate({
		...commonValidationFields,
		companyName: [isRequired, hasLengthMax(128)],
		registrationDate: [isRequired],
		[identifierType]: identifierSpecificValidations,
	})(values);

	return mergeDeepWithKey(alwaysNull, validariumValidations, validatePasswordsConfirm(values));
};

const GeneralInfoForm = ({ form, handleSubmit, onSubmit, userType, pristine, invalid }) => {
	const AmountFormatter = useAmountFormatter();
	const TimeSpanDurationFormatter = useTimeSpanDurationFormatter();
	const dispatch = useDispatch();
	const selectedPlan = useSelector(getSubscriptionPlan);
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(!recaptchaTurnedOn);
	const { defaultPhoneInputCountry } = useInstanceConfig();

	const [nationalitySpecificIdentifierDetail, nationalitySpecificIdentifierLabel] =
		useNationalitySpecificIdentifier(form, userType, IDENTIFIER_TYPE_FIELD);
	const [selectedCompanyIdentifierDetail, selectedCompanyIdentifierLabel, identifierSelectField] =
		useSelectableCompanyIdentifier(form, IDENTIFIER_TYPE_FIELD);

	useEffect(() => {
		dispatch(fetchTermsAndConditions(USERS_DICT[userType].toLowerCase()));
	}, [userType]);

	const handleSelectSubscription = key => dispatch(setSubscriptionPlan(key));
	const termsAndConditions = useSelector(selectTermsAndConditions);

	const confirmCaptchaVerification = captchaResponse => {
		setIsCaptchaVerified(true);
		dispatch(setCaptchaResponse(captchaResponse));
	};

	const isCompany = getIsUserTypeNumberCompany(userType);
	const isNextStepButtonDisabled = pristine || invalid || !isCaptchaVerified;
	// Added in order to fix "name" field validation issue. Please do not change back - validarium ignores field named "name"
	const nameField = isCompany ? 'companyName' : 'fullName';

	return (
		<Form
			onSubmit={handleSubmit(values =>
				onSubmit({
					...values,
					name: values[nameField],
					form,
				})
			)}
		>
			<Script src="https://www.google.com/recaptcha/api.js" />
			<Section py={4}>
				<Grid container>
					{selectedPlan && (
						<Grid row>
							<Grid col={{ xs: 12, lg: 8 }} offset={{ lg: 2 }} mb={{ xs: 3, md: 4 }}>
								<Heading as="h3" weight="semibold" align={['center', 'center', 'left']}>
									<Message {...registrationMessages.selectedPlan} />
								</Heading>
								<Grid alignItems="center" flexDirection={{ xs: 'column', md: 'row' }}>
									<Text
										icon="success"
										iconProps={{ color: 'teal' }}
										weight="bold"
										variant="tagline"
										color="black"
									>
										{selectedPlan.name}
										<Br display={{ md: 'none' }} />
										{selectedPlan.price > 0 && (
											<>
												{' ('}
												<AmountFormatter>{selectedPlan.price}</AmountFormatter>{' '}
												<Message {...registrationMessages.per} />
												<TimeSpanDurationFormatter>
													{selectedPlan.duration}
												</TimeSpanDurationFormatter>
												{') '}
											</>
										)}
									</Text>
									<Button
										kind="blank"
										size="xs"
										mt={{ xs: 3, md: 0 }}
										ml="auto"
										mr={{ xs: 'auto', md: 0 }}
									>
										<AnchorLink id="subscriptions">
											<Message {...registrationMessages.changePlan} />{' '}
											<Icon type="caretDown" size="sm" />
										</AnchorLink>
									</Button>
								</Grid>
							</Grid>
						</Grid>
					)}
					<Grid col={12} mb={{ xs: 3, lg: 4 }} className="text-center">
						<UserTypeSwitch />
					</Grid>
					<div id="subscriptions">
						<PricingTable
							userType={USERS_DICT[userType]}
							onSelect={handleSelectSubscription}
							selectedPlan={selectedPlan}
						/>
					</div>
				</Grid>
			</Section>
			{selectedPlan && (
				<Fragment>
					<Box as="section" className="section--bordered" pt={0} pb={4}>
						<Grid container>
							<Grid row>
								<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
									<Grid alignItems="center" mb={3}>
										<Text variant="tagline" icon="individual">
											<Message
												{...registrationMessages[isCompany ? 'companyInfo' : 'personalInfo']}
											/>
										</Text>
									</Grid>
									<Grid row>
										<Grid col={{ xs: 12, md: 6 }} mb={3}>
											<TextInputField
												label={
													isCompany ? (
														<Message {...registrationMessages.fieldCompanyName} />
													) : (
														<Message {...m.fieldFullName} />
													)
												}
												name={nameField}
												required
											/>
										</Grid>
										{isCompany ? (
											<Fragment>
												<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
													{identifierSelectField}
												</Grid>
												{selectedCompanyIdentifierDetail && (
													<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
														<TextInputField
															label={<Message {...selectedCompanyIdentifierLabel} />}
															name={selectedCompanyIdentifierDetail.name}
															required
														/>
													</Grid>
												)}
												<Grid col={{ xs: 12, md: 6 }}>
													<DatePickerField
														label={<Message {...m.fieldDateOfRegistration} />}
														name="registrationDate"
														required={getIsFieldRequiredWithIdentifier(
															'registrationDate',
															selectedCompanyIdentifierDetail
														)}
														constraint={pastConstraint}
													/>
												</Grid>
											</Fragment>
										) : (
											<Fragment>
												<Grid col={{ xs: 12, md: 6 }} mb={3}>
													<LookupSelectField
														lookup={Lookups.COUNTRY}
														label={<Message {...registrationMessages.fieldNationality} />}
														name="nationality"
														formValueProp="key"
														required
													/>
												</Grid>
												{nationalitySpecificIdentifierDetail && (
													<Grid col={{ xs: 12, md: 6 }} mb={3}>
														<TextInputField
															label={<Message {...nationalitySpecificIdentifierLabel} />}
															name={nationalitySpecificIdentifierDetail.name}
															required={
																nationalitySpecificIdentifierDetail.name !== 'CustomIdNumber1'
															}
														/>
													</Grid>
												)}
												<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
													<TextInputField
														label={<Message {...m.fieldPassportNumber} />}
														name="passportNumber"
														required={getIsFieldRequiredWithIdentifier(
															'PassportNumber',
															nationalitySpecificIdentifierDetail
														)}
													/>
												</Grid>
												<Grid col={{ xs: 12, md: 6 }}>
													<DatePickerField
														label={<Message {...registrationMessages.fieldDateOfBirth} />}
														name="dateOfBirth"
														required
														constraint={adultConstraint}
													/>
												</Grid>
											</Fragment>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box as="section" className="section--bordered" py={4}>
						<Grid container>
							<Grid row>
								<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
									<Grid alignItems="center" mb={3}>
										<Text variant="tagline" icon="loginInfo">
											<Message {...registrationMessages.loginInfo} />
										</Text>
									</Grid>
									<Grid row>
										<Grid col={{ xs: 12, md: 6 }} mb={3}>
											<TextInputField
												label={<Message {...registrationMessages.fieldEmail} />}
												name="email"
												type="email"
												warning={<Message {...registrationMessages.fieldEmailWarning} />}
												required
											/>
										</Grid>
										<Grid col={{ xs: 12, md: 6 }} mb={3}>
											<PhoneInputFieldDeprecated
												label={<Message {...registrationMessages.fieldPhoneNumber} />}
												name="phoneNumber"
												required
												defaultCountry={defaultPhoneInputCountry}
											/>
										</Grid>
										<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
											<PasswordInputFieldDeprecated
												name="password"
												label={<Message {...registrationMessages.fieldPassword} />}
												isRequired
												hasRulesPopover
											/>
										</Grid>
										<Grid col={{ xs: 12, md: 6 }}>
											<PasswordInputFieldDeprecated
												name="confirmPassword"
												label={<Message {...registrationMessages.fieldPasswordConfirm} />}
												isRequired
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box
						as="section"
						className="section--terms position-relative"
						pt={4}
						pb={{ xs: 4, lg: 0 }}
					>
						<Grid container>
							<Grid row>
								<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
									<Box mb={3}>
										{termsAndConditions?.data ? (
											<TermsAndConditionsTextContainer html={termsAndConditions.data} />
										) : (
											<Textarea
												className="text-body"
												label={<Message {...registrationMessages.fieldTermsConditions} />}
												labelProps={{ className: 'color-dark' }}
												name="terms-and-conditions"
												value={registrationMessages.termsConditions.defaultMessage}
												rows="10"
												readOnly
											/>
										)}
									</Box>
									<Box mb={3}>
										<CheckboxField
											label={<Message {...registrationMessages.confirmTermsConditions} />}
											name="agree"
											required
										/>
									</Box>
									{recaptchaTurnedOn && (
										<Box p={3} mb={3}>
											<Recaptcha
												sitekey={recaptchaSiteKey}
												onChange={captchaResponse => confirmCaptchaVerification(captchaResponse)}
											/>
										</Box>
									)}
									<MandatoryFieldsText mb={4} />
									<Grid flex>
										<Button href="/" kind="secondary" outline mr={3}>
											<Message {...registrationMessages.cancelButton} />
										</Button>
										<Button kind="primary" type="submit" disabled={isNextStepButtonDisabled}>
											<Message {...registrationMessages.continueButton} />
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<TextInputField type="hidden" name={IDENTIFIER_TYPE_FIELD} />
					</Box>
				</Fragment>
			)}
		</Form>
	);
};

GeneralInfoForm.propTypes = {
	form: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired,
	pristine: PropTypes.bool.isRequired,
	userType: PropTypes.number.isRequired,
};

export default GeneralInfoForm;
