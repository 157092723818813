import React, { useCallback, useState } from 'react';
import { Button, Text } from '@ci/atoms';
import { createUrl } from '@ci/utils';
import { Box, Grid } from '@myci/ui-components';
import { RadioField } from '@myci/ui-components-redux';
import Bureaus from '@myci/domain-bureaus';
import { Message } from '@myci/intl';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@gatsbyjs/reach-router';
import { setSubscriptionPlan } from '@myci/domain-registration';
import {
	RegistrationJourneyStepProps,
	messages as registrationMessages,
} from '@myci/module-registration';
import { prepareStyle, useStyles } from '@ci/styles';
import {
	getIsPkiAvailable,
	makeSelectFormValues,
	performMobilePkiAuthorization,
	performSmartcardPkiAuthorization,
	pkiMethods,
	selectIsAuthorizationInProgress,
} from '@myci/domain-instance-omn';
import { Storage, storageKeys } from '@myci/storage';

import m from '../../../../messages';

const wrapperStyle = prepareStyle(() => ({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
}));

const itemStyle = prepareStyle<{ isLast: boolean }>((utils, { isLast }) => ({
	alignItems: 'center',
	borderBottomStyle: 'solid',
	borderBottomWidth: utils.borders.widths.sm,
	borderColor: utils.colors.gray300,
	borderTopStyle: 'solid',
	borderTopWidth: isLast ? 0 : utils.borders.widths.sm,
	display: 'flex',
	justifyContent: 'space-between',
	margin: '0 auto',
	padding: utils.spacings.lg,
	width: '100%',
	selectors: {
		[utils.breakpoints.sm]: {
			width: '50%',
		},
		[utils.breakpoints.lg]: {
			width: '33%',
		},
	},
}));

const radioWrapperStyle = prepareStyle(() => ({
	flexGrow: 1,
}));

const iconWrapperStyle = prepareStyle(({ spacings }) => ({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
	marginInlineStart: spacings.sm,
	width: '3rem',
}));

const iconStyle = prepareStyle(() => ({
	marginBottom: 0,
}));
const topTextStyle = prepareStyle(({ spacings }) => ({
	marginBottom: spacings.lg,
	textAlign: 'center',
}));

const bottomTextWrapperStyle = prepareStyle(({ spacings }) => ({
	marginBottom: spacings.lg,
	marginTop: spacings.lg,
	textAlign: 'center',
}));

const bottomTextStyle = prepareStyle(() => ({
	marginBottom: 0,
}));

const Success = ({ form }: RegistrationJourneyStepProps) => {
	const dispatch = useDispatch();
	const formValues = useSelector(makeSelectFormValues(form));
	const isAuthorizationInProgress = useSelector(selectIsAuthorizationInProgress);
	const { applyStyle } = useStyles();
	const { identifierType, phoneNumber, pkiMethod } = formValues ?? {};
	const isPkiAvailable = getIsPkiAvailable(formValues);
	const [isPkiDisplayed, setIsPkiDisplayed] = useState(isPkiAvailable);
	const identifierValue = formValues?.[identifierType];

	const handleManualIdentificationButtonClick = useCallback(() => setIsPkiDisplayed(false), []);
	const handlePkiButtonClick = useCallback(() => setIsPkiDisplayed(true), []);
	const handlePerformPkiAuthorization = useCallback(() => {
		const pkiPayload = {
			username: formValues.email,
			phoneNumber: formValues.phoneNumber,
			mainIdName: formValues.identifierType,
			mainIdValue: formValues[formValues.identifierType],
			redirectUrl: createUrl([window.location.origin, '/sign-in']),
		};
		if (pkiMethod === pkiMethods.smartcard) {
			dispatch(performSmartcardPkiAuthorization(pkiPayload));
		} else {
			dispatch(performMobilePkiAuthorization(pkiPayload));
		}
	}, [identifierType, phoneNumber, identifierValue, pkiMethod]);

	return (
		<Box as="section" py={4}>
			<Grid container>
				<div className={applyStyle(wrapperStyle)}>
					{isPkiDisplayed ? (
						<>
							<Text customStyle={topTextStyle}>
								<Message {...m.pkiMethodSelection} />
							</Text>
							<div className={applyStyle(itemStyle, { isLast: false })}>
								<div className={applyStyle(radioWrapperStyle)}>
									<RadioField
										label={<Message {...m.mobilePki} />}
										name="pkiMethod"
										value={pkiMethods.mobile}
										isDisabled={isAuthorizationInProgress}
									/>
								</div>
								<div className={applyStyle(iconWrapperStyle)}>
									<img
										className={applyStyle(iconStyle)}
										src={require('@myci/assets/images/mobile-pki.svg')}
									/>
								</div>
							</div>
							<div className={applyStyle(itemStyle, { isLast: true })}>
								<div className={applyStyle(radioWrapperStyle)}>
									<RadioField
										label={<Message {...m.cardPki} />}
										name="pkiMethod"
										value={pkiMethods.smartcard}
										isDisabled={isAuthorizationInProgress}
									/>
								</div>
								<div className={applyStyle(iconWrapperStyle)}>
									<img
										className={applyStyle(iconStyle)}
										src={require('@myci/assets/images/card-pki.svg')}
									/>
								</div>
							</div>
							<div className={applyStyle(bottomTextWrapperStyle)}>
								<Text customStyle={bottomTextStyle}>
									<Message {...m.navigateToManualIdentification} />
									<br />
									<a href="javascript:;" onClick={handleManualIdentificationButtonClick}>
										<Message {...m.manualIdentificationLink} />
									</a>
								</Text>
							</div>
							<Button onClick={handlePerformPkiAuthorization} isBusy={isAuthorizationInProgress}>
								<Message {...m.identify} />
							</Button>
						</>
					) : (
						<>
							<Text customStyle={topTextStyle}>
								<Message {...m.manualIdentification} />
							</Text>
							<Grid row>
								<Bureaus />
							</Grid>
							{isPkiAvailable && (
								<div className={applyStyle(bottomTextWrapperStyle)}>
									<Text customStyle={bottomTextStyle}>
										<Message {...m.navigateToPkiVerification} />
										<br />
										<a href="javascript:;" onClick={handlePkiButtonClick}>
											<Message {...m.pkiVerificationLink} />
										</a>
									</Text>
								</div>
							)}
							<Button
								onClick={() => {
									dispatch(setSubscriptionPlan(null));
									Storage.removeItem(storageKeys.TEMP_REGISTRATION_DATA);
									navigate('/sign-in');
								}}
							>
								<Message {...registrationMessages.goToLogin} />
							</Button>
						</>
					)}
				</div>
			</Grid>
		</Box>
	);
};

export default Success;
